import emailjs from '@emailjs/browser';

interface PopupProps {
  popupState: boolean;
  email: string;
  setPopupState: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgreePopup = ({ popupState, email, setPopupState }: PopupProps) => {
  const Close = (event:any) => {
    event.stopPropagation();
    setPopupState(false);
  }

  const agreeClick = (event:any) => {
    event.stopPropagation();
      
    const MailServiceID = "service_bwffy0j";
    const MailTemplateID = "template_dr9h41r";
    const MailApiKey = "9ERbRF9L3s_Maf043";
    
    emailjs.send(MailServiceID, MailTemplateID, {
        message: email,
        reply_to: "",
      }, MailApiKey);
  
    alert('등록되었습니다.');
    // setMail('');

    setPopupState(false);
  }

  const NotClose = (event:any) => {
    event.stopPropagation();
  }
  
  // bg-black opacity-20
  return (
    <div className="agreePopup_wrap" onClick={Close}>
      <div className="container_agreePopup" onClick={NotClose}>
        <img 
          className="agreePopup_background"
          alt=""
          src="/img/close.png"
          width={0}
          height={0}
          onClick={Close}
        />
        <p className="agreePopup_string1">
          개인정보 수집 및 이용 안내
        </p>
        <p className="agreePopup_string2">
            유한회사 티슈는 tishoo.com 서비스 클로즈베타 서비스 오픈 시 등록하신
            이메일로 안내 드리기 위해 이메일 정보를 수집하고 이용합니다.
        </p>        
        <ol className="agreePopup_string3">
            <li className="mb-0">수집이용 항목: 이메일</li>
            <li className="mb-0">수집이용 목적: tishoo.com 서비스 오픈 안내</li>
            <li className="mb-0">보유 이용 기간: 수집일로 부터 1년</li>
        </ol> 
        <p className="agreePopup_string4">
          위 개인정보 수집 및 이용을 거부하실 경우 개인정보 수집 및 
          이용에 동의하지 않음을 선택해주세요.
        </p>
        <div className="agreePopup_string5">
          <div className="agreePopup_Btn1" onClick={Close}>
              동의하지 않습니다.
          </div>
          <div className="agreePopup_Btn2" onClick={agreeClick}>
              동의합니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreePopup;

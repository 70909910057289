import React from "react";

const Page04 = () => {
    return <div className="page04"style={{ height: "100vh" }}>
        <div className="container_page04">
            <p className="page04_string1">KEY FEATURE 02.</p>
            <p className="page04_string2">무결한 당신이 완벽한 업무를 하기 위한 방법</p>

            <p className="page04_string3">
                효율적인 프로젝트 관리 기능으로<br />팀업에서 부터 프로젝트 출시, 그 이상!
            </p>
        </div>        
    </div>;
}

export default Page04;
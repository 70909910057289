const Bottom = () => {
    return (
        <div className="bottom_wrap">
          <div className="bottom_menu">
            <img className="position_relative" alt="logo" 
              src="/img/logo-black.svg" width={98} height={29} />
            <div className="bottom_link">
              <div className="position_relative">운영 정책</div>
              <div className="position_relative">이용 약관</div>
              <div className="position_relative">개인정보 처리방침</div>
              <div className="position_relative">고객센터</div>
              <div className="position_relative">회사 소개</div>
              <div className="position_relative">인스타그램</div>
            </div>
          </div>            
          <div className="bottom_string_wrap">
            <div className="bottom_left">
              <p className="bottom_string1">Work Playground IT Professionals</p>
              <p className="bottom_string2">
                Find the perfect expert through detailed tags. <br/>
                You can discover professionals based on not only <br/>
                their job, experience, and skills but also their personality.
              </p>
            </div>
            <div className="bottom_right">
              <p className="w-[614px] h-[100px] mt-[-1.00px] leading-[21px] relative [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#6F6F6F] text-[14px] tracking-[0]">
                (유)티슈 | 대표 최미나 | 개인정보관리책임자 최미나
                <br />
                T. 02-6397-7101 | email: contact@tishoo.com
                <br />
                서울특별시 강남구 도산대로 53길 18-2, F3
                <br />
                사업자등록번호: 555-81-00886 <br />
                통신판매업신고번호: 제2024-서울강남-03740호
              </p>
            </div>
          </div>
          <div className="bottom_license">
            2023 © tishoo.llc All rights reserved.
          </div>
        </div>
      );    
}

export default Bottom;
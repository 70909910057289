import { FunctionComponent, useState } from "react";
import AgreePopup from '../components/AgreePopup'

const EmailForm: FunctionComponent = () => {
  const [mail, setMail] = useState('');
  const [popupState, setPopupState] = useState(false);

  const SendClick = (e: any) => {
    e.preventDefault();

    if (!isValiEmail(mail)) {
      alert('잘못된 메일 주소입니다.');
      return;
    } else {
      console.log('click', mail);
      setPopupState(true);
    }
  };

  const onChange = (event: any) => {
    setMail(event.target.value);
  }

  function isValiEmail(val:string) {
    const emailRegex  = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(val);
  }

  return (
    <>
      { popupState && <AgreePopup email={mail} popupState={popupState} setPopupState={setPopupState} />}
      <div className="emailForm_wrap">
        <div className="emailInput_wrap">
          <div className="inputDiv">
            <input className="inputEmail" type="text" placeholder="Enter email address" value={mail} onChange={onChange} />
          </div>
        </div>
        <div className="buttonEmail_wrap" onClick={SendClick}>
          <div className="buttonEmail" onClick={SendClick}>
            SEND
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailForm;
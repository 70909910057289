import React from "react";
import EmailForm from "../components/EmailForm"
const Page01 = () => {
    return <div className="page01" style={{ height: "100vh" }}>
        <div className="main_string">
            <p className="top_string1">We are Almost there!</p>
            <p className="top_string2">If you want to get notified when the service open, subscribe to our mailing list!</p>
            <p className="string1">재미있게 오래오래</p>
            <p className="string2">IT쟁이들의 일 놀이터</p>
            <p className="string3">티슈</p>
            <div className="container_emailForm">
                <EmailForm />
            </div>            
            <p className="string4">
                tishoo.com 클로즈베타 서비스 초대장을 받으시려면 이메일을 등록해주세요.<br/>
                순차적으로 초대장을 보내드립니다
            </p>
        </div>
        
        <div className="page01_bottom">Enjoy Tishoo in the safest playground. tishoo, we make it possible to pursue your desired projects from anywhere.</div>
        <div className="page01_bottom_mobile">
            Enjoy Tishoo in the safest playground.<br/>
            tishoo, we make it possible to pursue your desired projects from anywhere.
        </div>
    </div>;
}

export default Page01;
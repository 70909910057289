import React from "react";

const Page03 = () => {
    return <div className="page03"style={{ height: "100vh" }}>
        <div className="container_page03">
            <p className="page03_string1">KEY FEATURE 01.</p>
            <p className="page03_string2">완벽한 파트너를 만나는 가장 완벽한 방법</p>

            <p className="page03_string3">
                성향까지 파악해 추천하는<br />오직 Tishoo에서만 가능한 팀빌딩
            </p>
            <p className="page03_string3_mobile">
                성향까지 파악해 추천하는<br />오직 Tishoo에서만<br/> 가능한 팀빌딩
            </p>
        </div>
    </div>;
}

export default Page03;
import React from "react";

const Page05 = () => {
    return <div className="page05"style={{ height: "100vh" }}>
        <div className="container_page05">
          <p className="page05_string1">지금, 티슈에서<br />완벽한 파트너와 함께 성장하세요.</p>
          
          <p className="page05_string2">
            당신이 오래오래 재미있게 일할 수 있는 서비스를 만듭니다.
          </p>          
        </div>        
    </div>;
}

export default Page05;
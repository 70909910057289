import React from "react";

const Page02 = () => {
    const appleLink = () => {
        alert("준비 중입니다.");
    };

    const googleLink = () => {
        alert("준비 중입니다.");
    };
    return <div className="page02"style={{ height: "100vh" }}>
        <div className="container_page02">
            <p className="page02_string1">내 프로젝트와 딱 맞는<br/>파트너를 만나는 신박한 방법</p>
            <p className="page02_string2">
                내 프로젝트에 fit한 일잘러들을 지금 만나보세요.<br/>
                스킬은 물론 성향까지 모두 알아볼 수 있어요.
            </p>
            <img src="/img/btn_apple.png" alt="app store" onClick={appleLink} className="downloadApple" />
            <img src="/img/btn_google.png" alt="google" onClick={googleLink} className="downloadGoogle" />
        </div>
        <div className="page02_img">
            <img className="page02_image" src="/img/section2-art.png" alt="alt board" />
        </div>
    </div>;
}

export default Page02;
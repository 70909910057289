
const TopMenu = () => {
    return <div className="header_wrap">
    <div className="header">
      <div className="menu_wrap">
        <img
          src="/img/logo.svg"
          alt="tishoo"
          className="logo"
        />

        <div className="topMenuLink">
          <div className="topMenu1">
            티슈 닷컴 소개
          </div>
          <div className="topMenu2">
            회사 소개
          </div>
          <div className="topMenu2">
            IR 자료
          </div>
          <div className="topMenu3"></div>
        </div>

        <div className="btn_join">
          JOIN
        </div>
      </div>
    </div>
  </div>
}

export default TopMenu;
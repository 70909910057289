import React from "react";
import EmailForm from "../components/EmailForm";


const Page06 = () => {
    return <div className="page06" style={{ height: "100vh" }}>
       <div className="page06_wrap">
        <img className="object-cover page06_img1"
            alt="" src="/img/tishoo-movie.gif" width={616} height={240} />        
        <img className="object-cover pt-11 page06_img2"
          alt="" src="/img/areyoulookingfor.gif" width={763} height={56} />
        <div className="container_emailForm">
          <EmailForm />
        </div>
        <div className="page06_string1">
            tishoo.com 클로즈베타 서비스는 이메일을 통해 초대 받은 사용자만 사용 가능합니다. 
        </div>
      </div>       
    </div>;
}

export default Page06;